<template>
    <div>
        <h4 v-if="$route.path.includes('report')" class="bold text-uppercase">Nhật ký tạo đơn lỗi</h4>
        <div class="row form-group align-items-center user-error-order-report">
            <div class="col-md-4 col-12">
                <h6 class="bold mb-0">Tìm theo username:</h6>
                <form @submit.prevent="getErrorPackageNames">
                    <input
                        type="text"
                        name="search_username"
                        id="search_username"
                        placeholder="Nhập username"
                        v-model="search_username"
                    />
                </form>
            </div>
            <div class="col-md-4 col-12 mt-2 mb-2">
                <h6 class="bold mb-0">Tìm theo object_id:</h6>
                <form @submit.prevent="getErrorPackageNames">
                    <input
                        type="text"
                        name="search_object_id"
                        id="search_object_id"
                        placeholder="Nhập object_id"
                        v-model="search_object_id"
                    />
                </form>
            </div>
            <div class="col-md-4 col-12">
                <h6 class="bold mb-0">Tải tối đa:</h6>
                <select class="form-control select-light" v-model="limitLogs" @change="getErrorPackageNames()">
                    <option value="100">Load 100 nhật ký</option>
                    <option value="200">Load 200 nhật ký</option>
                    <option value="300">Load 300 nhật ký</option>
                    <option value="500">Load 500 nhật ký</option>
                    <option value="1000">Load 1000 nhật ký</option>
                </select>
            </div>
        </div>
        <datatable :stt="true" :columns="columns" :rows="data"> </datatable>
    </div>
</template>
<script>
import { getErrorPackageNamesByQuery } from "../../api/user"
import datatable from "@/components/datatable/datatable.vue"
import { buildParamQuery, catchError, formatNumber } from "../../helpers"

export default {
    name: "user-error-order-report",
    components: {
        datatable
    },
    data() {
        return {
            limitLogs: 100,
            search_object_id: "",
            search_username: "",
            data: [],
            columns: [
                {
                    label: "Tên dịch vụ",
                    field: "name"
                },
                {
                    label: "Username",
                    field: "username"
                },
                {
                    label: "Đối Tượng",
                    field: "object_id"
                    // fb_link: true
                },
                {
                    label: "Nội dung",
                    field: "content"
                },
                {
                    label: "Tiền",
                    field: "coin_html",
                    html: true
                },
                {
                    label: "Thời Gian Tạo",
                    field: "created_at"
                }
            ],
            errorObjectList: []
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.site.site
        },
        reload() {
            return this.$store.state.app.reload
        },
        toolList() {
            return this.$store.state.tools.tools
        }
    },
    watch: {
        errorObjectList: function(v) {
            this.data = v.map(obj => {
                return obj
            })
        }
    },
    created() {
        this.getErrorPackageNames()
    },
    methods: {
        handleShowPanel: async function(panel) {
            this.panel = panel
            if (panel == "logs") {
                this.getNotify()
            }
        },
        getErrorPackageNames: async function() {
            this.errorObjectList = []
            const params = {
                limit: this.limitLogs,
                username: this.search_username,
                object_id: this.search_object_id
            }
            const query = buildParamQuery(params)
            const response = await getErrorPackageNamesByQuery(query)
            if (response.status === 200 && response.success) {
                const errorPackageNames = []
                response.data.forEach(pck => {
                    errorPackageNames.push(pck.package_name)
                })
                this.toolList.forEach(tool => {
                    if (tool.prices && tool.prices.length > 0) {
                        tool.prices.forEach(price => {
                            response.data.forEach(pck => {
                                if (
                                    price.package_name === pck.package_name &&
                                    this.errorObjectList.indexOf(price) === -1
                                ) {
                                    price.object_id = pck.object_id
                                    price.username = pck.username
                                    price.old_coin = pck.old_coin
                                    price.new_coin = pck.new_coin
                                    price.content =
                                        "Tạo đơn thất bại dịch vụ " + price.name + " cho object_id " + pck.object_id
                                    price.coin_html = this.coinCalculate(pck.old_coin, pck.new_coin)
                                    this.errorObjectList.push(price)
                                }
                            })
                        })
                    }
                })
            } else this.$swal("Lỗi", catchError(response), "error")
        },
        coinCalculate(oldCoin, newCoin) {
            const oldCoinHtml = "<span class='py-1 badge badge-yellow'>" + formatNumber(oldCoin) + "</span>"
            const newCoinHtml = "<span class='py-1 badge badge-danger'>" + formatNumber(newCoin) + "</span>"
            const difference = parseInt(oldCoin) - parseInt(newCoin)
            const differenceCoin = Math.abs(difference)
            const differenceHtml = "<span class='py-1 badge badge-success'>" + formatNumber(differenceCoin) + "</span>"
            if (difference > 0) {
                const html = oldCoinHtml + " - " + differenceHtml + " = " + newCoinHtml
                return html
            } else {
                const html = oldCoinHtml + " + " + differenceHtml + " = " + newCoinHtml
                return html
            }
        }
    }
}
</script>

<style>
.user-error-order-report input {
    border: none;
    padding: 7px;
    width: 100%;
}

.user-error-order-report select {
    padding: 7px !important;
}
</style>
